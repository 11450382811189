import React from 'react'
import { Link } from "gatsby"

import SEO from '../components/seo'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import Layout from '../components/layout'


export default () => {
  return (
    <Layout>
      <SEO title="Сангийн хөтөлбөр" />
        <div className="page-container page-container-mt">
            <Container>
                <Row>
                    <Col>
                    <Breadcrumb>
                      <li className="breadcrumb-item">
                        <Link to="/">Нүүр</Link>
                      </li>
                      <Breadcrumb.Item active>Сангийн хөтөлбөр</Breadcrumb.Item>
                    </Breadcrumb>
                      <h1 className="mb-5">Сангийн хөтөлбөр</h1>
                      <div>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
